//Configuración específica de prod
import {merge} from 'lodash';
import configBase from './configBase';

const config = {
	"environment": "test",
	"apis": {
		"authApi": {
			"URL": "https://test.authapi.safi.cgmisiones.gob.ar"
		},
		"presupuestoApi": {
			"URL": "https://test.api.presupuesto.cgmisiones.gob.ar/v1",
			"URL_V2": "https://test.api.presupuesto.cgmisiones.gob.ar/v2",
			"VERSION": "https://test.api.presupuesto.cgmisiones.gob.ar"
		},
		"coreApi": {
			"URL": "https://test.coreapi.cgmisiones.gob.ar"
 		},
 		"fundsRequestsApi": {
 			"URL": "https://test.api.fondos.cgmisiones.gob.ar"
 		}
	}
};

export default merge(configBase, config);