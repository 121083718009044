import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller, FormContext, useFormContext } from 'react-hook-form';
import { Form, Col, Row, Button, Modal, Table, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGoodsAndServicesForm } from 'src/components/pages/ProvisionOrder/hooks/useGoodsAndServicesForm';
import { faAsterisk, faPlus, faMinusCircle, faEdit } from "@fortawesome/free-solid-svg-icons";
import ActionIcon from 'src/components/general/ActionIcon';
import { isNotEmptyArray } from 'src/services/validationService';
import NumberFormat from 'react-number-format';

const GoodsAndServicesModal = (props) => {
    const dispatch = useDispatch();
    const { show, handleClose, goodsAndServicesList, setGoodsAndServicesList, provisionOrder } = props;
    const hookFormMethods = useForm();
    const { handleSubmit, register, control, setValue, errors } = hookFormMethods;
    const copy = JSON.parse(JSON.stringify(goodsAndServicesList));
    const [goodsAndServicesArray, setGoodsAndServicesArray] = useState(copy);
    const {
        defaultParams,
        stopPropagate,
        id,
        measurementUnitList,
        provisionRowNumber,
        description,
        quantity,
        units,
        unitPrice,
        totalAmount,
        observations,
        handleChangeId,
        handleChangeLine,
        handleChangeDescription,
        handleChangeAmount,
        handleChangeUnits,
        handleChangeUnitPrice,
        handleChangeTotalPrice,
        handleChangeObservation,
        addValidation,
        clearForms,
        withValueLimitImports,
        withValueLimitQuantity
    } = useGoodsAndServicesForm();

    const addLine = () => {
        const idRandom = Math.random().toString(30).substring(2);
        const params = {
            id,
            idRandom: id ? id : idRandom,
            provisionRowNumber,
            quantity,
            observations,
            units: units.toUpperCase(),
            unitPrice,
            totalAmount,
            description,
        };
        clearForms();
        const copy = JSON.parse(JSON.stringify(goodsAndServicesArray));
        const newList = (copy || []).concat({ ...params });
        newList?.sort((a, b) => a?.provisionRowNumber - b?.provisionRowNumber);
        setGoodsAndServicesArray(newList);
    };

    //Functions
    const onClickRemove = idRandom => {
        const newList = (goodsAndServicesArray || []).filter(item => item.idRandom != idRandom);
        setGoodsAndServicesArray(newList);
    };

    const onClickEdit = value => {
        const newList = (goodsAndServicesArray || []).filter(item => item?.idRandom != value?.idRandom);
        handleChangeId(value?.id || '');
        setGoodsAndServicesArray(newList);
        handleChangeLine(value?.provisionRowNumber);
        handleChangeDescription(value?.description);
        handleChangeAmount({ floatValue: value?.quantity });
        handleChangeUnits(value?.units);
        handleChangeUnitPrice({ floatValue: value?.unitPrice });
        handleChangeObservation(value?.observations);
        handleChangeTotalPrice(value?.unitPrice, value?.quantity);
    };

    const onSubmitForm = (data) => {
        setGoodsAndServicesList(goodsAndServicesArray);
        clearForms();
        handleClose();
    };

    const handleCloseModal = () => {
        clearForms();
        const copy = JSON.parse(JSON.stringify(goodsAndServicesList || []));
        setGoodsAndServicesArray(copy);
        handleClose();
    };

    useEffect(() => {
        clearForms();
        goodsAndServicesList.forEach(objeto => {
            objeto.idRandom = Math.random().toString(30).substring(2);
        });
        setGoodsAndServicesArray(goodsAndServicesList?.sort((a, b) => a?.provisionRowNumber - b?.provisionRowNumber));
    }, [goodsAndServicesList]);

    return <>
        <Modal
            show={show}
            onHide={handleCloseModal}
            scrollable={true}
            size='xxl'
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Orden de Provisión</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <FormContext {...hookFormMethods}>
                    <Form onSubmit={stopPropagate(handleSubmit(onSubmitForm))} autocomplete='off' className='mt-4'>

                        <Form.Group as={Row}>
                            <Form.Label className='text-black-color pr-0 d-flex mandatory-label' column sm={4}>
                                <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
                                Renglón:
                            </Form.Label>
                            <Col sm={4}>
                                <Form.Control
                                    type='number'
                                    name='provisionRowNumber'
                                    value={provisionRowNumber}
                                    onChange={event => handleChangeLine(event?.target?.value)}
                                    placeholder='Ingresar renglón'
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label className='text-black-color pr-0 d-flex mandatory-label' column sm={4}>
                                <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
                                Descripción:
                            </Form.Label>
                            <Col sm={4}>
                                <Form.Control
                                    as="textarea"
                                    rows={2}
                                    name="description"
                                    value={description}
                                    onChange={event => handleChangeDescription(event?.target?.value)}
                                    placeholder="Descripción"
                                    maxLength={3000}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label className='text-black-color pr-0 d-flex mandatory-label' column sm={4}>
                                <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
                                Cantidad:
                            </Form.Label>
                            <Col sm={4}>                                
                                <NumberFormat
                                    className='form-control text-right'
                                    decimalScale={6}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    inputMode='numeric'
                                    value={quantity}
                                    allowLeadingZeros={false}
                                    isAllowed={(inputObj) => withValueLimitQuantity(inputObj)}
                                    onValueChange={(inputObject) => handleChangeAmount(inputObject)}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label className='text-black-color pr-0 d-flex mandatory-label' column sm={4}>
                                <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
                                U. Medida:
                            </Form.Label>
                            <Col sm={4}>
                                <Form.Control
                                    as="select"
                                    name="units"
                                    value={units}
                                    onChange={event => handleChangeUnits(event?.target?.value)}
                                    className={"text-black-color"}
                                >
                                    <option value={''} selected>Seleccione una opción...</option>
                                    {
                                        measurementUnitList?.map(item => (
                                            <option className='text-black-color' value={item?.name} key={item}>
                                                {item?.name}
                                            </option>
                                        ))
                                    }
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label className='text-black-color pr-0 d-flex mandatory-label' column sm={4}>
                                <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
                                Precio Unitario:
                            </Form.Label>
                            <Col sm={4}>
                                <div className='input-group'>
                                    <div className='input-group-prepend'>
                                        <div className='input-group-text'>
                                            $
                                        </div>
                                    </div>
                                    <NumberFormat
                                        className='form-control text-right'
                                        decimalScale={6}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                        inputMode='numeric'
                                        value={unitPrice}
                                        allowLeadingZeros={false}
                                        isAllowed={(inputObj) => withValueLimitImports(inputObj)}
                                        onValueChange={(inputObject) => handleChangeUnitPrice(inputObject)}
                                    />
                                </div>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label className='text-black-color pr-0 d-flex mandatory-label' column sm={4}>
                                <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
                                Precio Total:
                            </Form.Label>
                            <Col sm={4}>
                                <NumberFormat
                                    prefix={'$'}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    allowLeadingZeros={false}
                                    decimalScale={2}
                                    value={totalAmount}
                                    className={'form-control text-right'}
                                    readOnly
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label className='text-black-color pr-0 d-flex mandatory-label' column sm={4}>
                                Observaciones:
                            </Form.Label>
                            <Col sm={4}>
                                <Form.Control
                                    type="text"
                                    name="observations"
                                    value={observations}
                                    onChange={event => handleChangeObservation(event?.target?.value)}
                                    placeholder="Observaciones"
                                    maxLength={255}
                                />
                            </Col>
                            <ActionIcon
                                size='lg'
                                text="Añadir renglón"
                                toolTipText="Añadir"
                                icon={faPlus}
                                disabled={!addValidation()}
                                onClick={addLine}
                            />
                        </Form.Group>

                        {isNotEmptyArray(goodsAndServicesArray) &&
                                <Table striped bordered hover size='sm'>
                                    <thead>
                                        <tr>
                                            <th className='text-center' style={{ "width": "6%" }}>Renglón</th>
                                            <th className='text-center' style={{ "width": "24%" }}>Descripción</th>
                                            <th className='text-center' style={{ "width": "14%" }}>Observaciones</th>
                                            <th className='text-center' style={{ "width": "10%" }}>Cantidad</th>
                                            <th className='text-center' style={{ "width": "8%" }}>U. Medida</th>
                                            <th className='text-center' style={{ "width": "15%" }}>$ Unitario</th>
                                            <th className='text-center' style={{ "width": "15%" }}>$ Total</th>
                                            <th className='text-center' style={{ "width": "8%" }}>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-black-color'>
                                        {goodsAndServicesArray?.map(item => (
                                            <tr key={item?.idRandom}>
                                                <td className='text-center'>
                                                    {item?.provisionRowNumber}
                                                </td>
                                                <td className='text-center'>
                                                    {item?.description}
                                                </td>
                                                <td className='text-center'>
                                                    {item?.observations || '-'}
                                                </td>
                                                <td className='text-center'>
                                                    <NumberFormat
                                                        title={item?.quantity}
                                                        thousandSeparator={'.'}
                                                        decimalSeparator={','}
                                                        allowLeadingZeros={false}
                                                        decimalScale={6}
                                                        value={item?.quantity}
                                                        className={'form-control text-right'}
                                                        readOnly
                                                    />
                                                </td>
                                                <td className='text-center'>
                                                    {item?.units}
                                                </td>
                                                <td className='text-center'>
                                                    <NumberFormat
                                                        title={item?.unitPrice}
                                                        prefix={'$'}
                                                        thousandSeparator={'.'}
                                                        decimalSeparator={','}
                                                        allowLeadingZeros={false}
                                                        decimalScale={6}
                                                        value={item?.unitPrice}
                                                        className={'form-control text-right'}
                                                        readOnly
                                                    />
                                                </td>
                                                <td className='text-center'>
                                                    <NumberFormat
                                                        title={item?.totalAmount}
                                                        prefix={'$'}
                                                        thousandSeparator={'.'}
                                                        decimalSeparator={','}
                                                        allowLeadingZeros={false}
                                                        decimalScale={2}
                                                        value={item?.totalAmount}
                                                        className={'form-control text-right'}
                                                        readOnly
                                                    />
                                                </td>
                                                <td className='text-center align-middle'>
                                                    <ActionIcon
                                                        size='sm'
                                                        icon={faEdit}
                                                        id='editItem'
                                                        toolTipText='Editar'
                                                        className='text-danger mr-2'
                                                        onClick={() => onClickEdit(item)}
                                                    />
                                                    <ActionIcon
                                                        size='sm'
                                                        icon={faMinusCircle}
                                                        id='removeItem'
                                                        toolTipText='Remover'
                                                        className='text-danger mr-2'
                                                        onClick={() => onClickRemove(item?.idRandom)}
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </Table>
                        }

                        <div className='d-flex justify-content-around mt-4 mb-3'>
                            <Button type='button' variant='primary' size='lg' onClick={handleCloseModal}>
                                Cerrar
                            </Button>
                            <Button
                                type='submit'
                                variant='success'
                                size='lg'
                                hidden={false}
                                disabled={!isNotEmptyArray(goodsAndServicesArray)}
                            >
                                Guardar
                            </Button>
                        </div>
                    </Form>
                </FormContext>
            </Modal.Body>
        </Modal>

    </>
};

export default GoodsAndServicesModal;