import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useForm } from 'react-hook-form';
import { Container, Card, Table, Button, Alert, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faExclamationTriangle, faSearch, faTrash, faBroom, faFilter, faDownload, faEdit, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import swal from 'sweetalert';
import axios from 'axios';
import { isNotEmptyArray } from 'src/services/validationService';
import ActionIcon from 'src/components/general/ActionIcon';
import { questionAnnulConfirm } from 'src/utils/label';
import { dateNeutralFormatedToShowARG, getOrderPayTypeValueToShow, getClearObject, orderFormsReadyToDisplay } from 'src/utils/utils';
import { getUserPermissionsPaymentOrders } from 'src/redux/login/loginReducer';
import { ORDER_PAY_NEW, ORDER_PAY_DETAILS, ORDER_PAY_EDIT } from 'src/utils/constants';
import { tryListOrderPay, tryDeleteOrderPay, tryListPayOrderTypes, tryOrderPayById, tryListWorkCertificateTypes } from 'src/redux/orderPay/orderPayActionCreator';
import { clearListOrderPayData, setOrderPayToDetail, clearListOrderPayLast, setOrderPayToEdit, clearSelectedTypeOrderPay, clearDetailOrderPayData } from 'src/redux/orderPay/orderPayActions'
import { getOrderPayListData, getOrderPayListIsFetching, getPayOrderTypeListData, getOrderPayByIdIsFetching } from 'src/redux/orderPay/orderPayReducer';
import RCPagination from 'src/components/common/RCPagination';
import AppLoading from 'src/components/common/AppLoading.js';
import { tryGetReportAffectationsByAdministrativeDocumentDirectDownload, tryGetReportPaymentOrderById } from 'src/redux/reports/reportsActionCreactor';
import { getReportpassiveWasteIsFetching } from 'src/redux/reports/reportsReducer';
import { getListAllServiceAdministrativeByUserIsFetching, getListIdsServiceAdministrativeByUser, getListServicesByUserAndExerciseIsFetching } from "src/redux/administrativeService/administrativeServiceReducer";
import { getReportAffectationsByAdministrativeDocumentDownloadIsFetching } from 'src/redux/reports/reportsReducer';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer.js';
import { getServicesByExerciseIdData, getServicesByExerciseIdIsFetching } from 'src/redux/exercise/exerciseReducer.js';
import { tryGetServicesByExerciseId } from 'src/redux/exercise/exerciseActionCreator.js';
import { administrativeServiceClose, AdministrativeServiceData, VALIDATE_ACTION_TYPE } from 'src/utils/administrativeServiceUtils.js';
import { getEmail } from 'src/redux/login/loginReducer';
import { getUserListData } from 'src/redux/user/userReducer';
import { tryListServicesByUserAndExercise } from "src/redux/administrativeService/administrativeServiceActionCreator";
import { faInfo, faInfoCircle } from '../../../../node_modules/@fortawesome/free-solid-svg-icons/index';

const OrderPayListPage = () => {
    const dispatch = useDispatch();
    const { handleSubmit, register, reset } = useForm();
    const [filterObject, setFilterObject] = useState({});
    const [totalCount, setTotalCount] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    
	const creditLabelsRefContainer = useRef(null);
	const colSpanCellsNumber = creditLabelsRefContainer?.current?.cells.length;
    const reportIsFetching = useSelector(state => getReportpassiveWasteIsFetching(state));

    // Permissions
    const organismsPermissions = useSelector(state => getUserPermissionsPaymentOrders(state));
    const listIdsServiceAdministrative = useSelector(state => getListIdsServiceAdministrativeByUser(state));
    const userAdmServIsFetching = useSelector(state => getListAllServiceAdministrativeByUserIsFetching(state));

    //Order pay
    const orderPayByIdIsFetching = useSelector(state => getOrderPayByIdIsFetching(state));
    const servicesByUserAndExerciseIsFetching = useSelector(state => getListServicesByUserAndExerciseIsFetching(state));

    //Download report by administrative document
    const reportAffectationsByAdministrativeDocumentDownloadIsFetching = useSelector(state => getReportAffectationsByAdministrativeDocumentDownloadIsFetching(state));

    // Order Pay
    const orderPayList = useSelector(state => getOrderPayListData(state)?.records);
    const isFetching = useSelector(state => getOrderPayListIsFetching(state));
    const payOrderTypeList = useSelector(state => getPayOrderTypeListData(state)) || [];
    const hasorderPayList = isNotEmptyArray(orderPayList);
    const selectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));    
    const servicesData = useSelector( state => getServicesByExerciseIdData(state) );
	const servicesDataIsFetching = useSelector( state => getServicesByExerciseIdIsFetching(state) );

    // User data
    const email = useSelector(state => getEmail(state));
    const listUserData = useSelector(state => getUserListData(state));
    const userFound = listUserData?.records?.find(item => item.email == email);

   	// PDF modal
	const clickDownloadTransactionReportPDF = params => {
        dispatch(tryOrderPayById(params?.id)).then((response) => {
            if(response?.id){
                const paramsToSend = {
                    periodId: selectedPeriod?.id,
                    affectationTransactionId: response?.details[0]?.presupuestoResponse?.affectationTransactionId,
                    outputFormat: 1
                };
                dispatch(tryGetReportAffectationsByAdministrativeDocumentDirectDownload(paramsToSend));
            };
        });
	};

    const loadAllData = () => {
        dispatch(clearListOrderPayData());
        dispatch(clearListOrderPayLast());
        fetchList(defaultParams);
    }

    useEffect(() => {
        listIdsServiceAdministrative && loadAllData();
        dispatch(clearSelectedTypeOrderPay());
        dispatch(tryListPayOrderTypes());
    }, [listIdsServiceAdministrative]);

    useEffect(() => {
		dispatch(clearDetailOrderPayData());
		dispatch(tryGetServicesByExerciseId(selectedPeriod?.id));
        dispatch(tryListServicesByUserAndExercise(userFound?.id, selectedPeriod?.id));
        dispatch(tryListWorkCertificateTypes());
    }, [])

    const onPageChange = (pNumber) => {
        const params = {
            ...defaultParams,
            ...filterObject,
            administrativeServiceId: serviceId,
            page: pNumber - 1 
        }
        fetchList(params);
    }

    const onClickDetail = orderPay => {
        dispatch(setOrderPayToDetail(orderPay));
        dispatch(push(ORDER_PAY_DETAILS));
    };

    const handleDownload = id => {
        dispatch(tryGetReportPaymentOrderById(id));
    }

    const onClickEdit = orderPay => {
        dispatch(tryOrderPayById(orderPay.id)).then(
			(response) => {
				dispatch(setOrderPayToEdit(response));
                dispatch(push(ORDER_PAY_EDIT));
			}
		);        
    };
    
    //Cancel Request
    const CancelToken = axios.CancelToken;
    let cancelSource = CancelToken.source();

    const defaultParams = {
        page: 0,
        size: 10,
        sort: 'number,desc',
        administrativeServiceId: listIdsServiceAdministrative,
        year: selectedPeriod?.year
    };
    const administrativeServiceData = AdministrativeServiceData();
    const [serviceId, setServiceId] = useState(listIdsServiceAdministrative);
    const handleAdministrativeService = value => {
        const serviceValue = value != '' ? value : defaultParams?.administrativeServiceId
        setServiceId(serviceValue)
        const newFilterObject = {
            ...defaultParams,
            ...filterObject,
            year: selectedPeriod?.year,
            administrativeServiceId: serviceValue,
        };
        fetchList(newFilterObject);
    };

    const clearFilters = () => {
        setFilterObject({})
        reset();
        loadAllData();
        setServiceId(listIdsServiceAdministrative);
    };

    const fetchList = (params) => {
        dispatch(tryListOrderPay(params)).then((response) => {
            setPageNumber(params.page);
            let metadata = response?.data?.metadata;
            setTotalCount(metadata?.filteredCount);
            setTotalItems(metadata.total);
        })
    };

    const onClickNew = () => {
        dispatch(push(ORDER_PAY_NEW));
    };

    const onSubmitForm = (data) => {
        if (!isFetching) {
            cancelSource.cancel();
            cancelSource = CancelToken.source();
            let newFilterObject = { 
                ...defaultParams,           
                ...data,
                administrativeServiceId: serviceId,
            };
            newFilterObject = getClearObject(newFilterObject);
            setFilterObject(newFilterObject);
            fetchList(newFilterObject);
        }
    };
    
    const onClickDelete = orderPay => {
        let nameOrderPay = getOrderPayTypeValueToShow(orderPay?.type) + ' Nro.: ' +  orderPay?.number  + ' Expediente: ' + orderPay?.administrativeDocument?.codeOrganism + '-' + orderPay?.administrativeDocument?.number + '/' + orderPay?.administrativeDocument?.year;
        swal({
            title: 'Confirmación',
            text: '¿' + questionAnnulConfirm + nameOrderPay + '?',
            icon: 'warning',
            buttons: ["Cancelar", "Aceptar"]
        })
            .then((willDelete) => {
                if (willDelete) {
                    dispatch(tryDeleteOrderPay(orderPay?.id)).then(
                        response => {
                            if (response?.status == 204) {
                                dispatch(clearListOrderPayData());
                                loadAllData();
                            }
                        }
                    );
                }
            });
    };

    const validateActionIcon = (item, type) => {
        let actionIconItem = {
            text: '',
            validation: false
        };
        if(type === VALIDATE_ACTION_TYPE.DELETE) {
            if(item?.slimFundRequest) {
                actionIconItem.text = "No puede anular una Orden de Pago con Pedido de Fondo asociado";
            } else if (administrativeServiceClose(servicesData, item?.administrativeServiceId)) {  
                actionIconItem.text = "No puede anular una Orden de Pago con Servicio cerrado dentro del ejercicio";
            } else {
                actionIconItem.text = "Anular Orden de Pago", 
                actionIconItem.validation = true
            }
        };
        if(type === VALIDATE_ACTION_TYPE.UPDATE) {
            if(item?.slimFundRequest) {
                actionIconItem.text = "No puede editar una Orden de Pago con Pedido de Fondo asociado";
            } else if (administrativeServiceClose(servicesData, item?.administrativeServiceId)) {  
                actionIconItem.text = "No puede editar una Orden de Pago con Servicio cerrado dentro del ejercicio";
            } else {
                actionIconItem.text = "Editar Orden de Pago", 
                actionIconItem.validation = true
            }
        };
        return actionIconItem
    };

    return <>
        <>
            <Container fluid>
                <Card className='mb-3'>
					<Card.Header className='d-flex justify-content-between'>
                        <h1 className="h6 mt-1 mb-0">Orden de Pago</h1>
						<a className='text-white' target="_blank" href='https://dev.kb.cgmisiones.gob.ar/docs/safi2/operador-servicios/#ejecuci%C3%B3n-de-presupuesto---orden-de-pago'>
                            <FontAwesomeIcon icon={faInfoCircle} className='mr-2' />
                            <small>Ver manual de uso</small> 
                        </a>
                	</Card.Header>
                    <Card.Body>
                        <div className='d-flex'>     
                            {organismsPermissions?.canCreate &&
                                <Button size='md' className='mb-3' variant='success' onClick={onClickNew}>
                                    <FontAwesomeIcon icon={faPlus} className='mr-1' />
                                    Nueva orden de pago
                                </Button>
                            }
                            {administrativeServiceData.length > 1 &&
                                <select
                                    value={serviceId}
                                    className='form-control col-3 mx-3'
                                    onChange={(e) => handleAdministrativeService(e.target.value)}
                                >
                                    <option value={''}>Todos los servicios</option>
                                    {Array.isArray(administrativeServiceData) && 
                                        administrativeServiceData.map(item => (
                                            <option className='py-2' value={item.id} key={item.id}>
                                                {item.code} - {item.shortName}
                                            </option>
                                        ))
                                    }
                                </select>
                            }
                        </div>
                        <Form onSubmit={handleSubmit(onSubmitForm)} autocomplete='off'>
                        <Table striped hover size='sm' responsive>
                            <thead>
                                <tr ref={creditLabelsRefContainer}>
                                    <th className='text-center align-middle' width='10%'>Expediente</th>
                                    <th className='text-center align-middle' width='25%'>Tipo de orden</th>
                                    <th className='text-center align-middle' width='10%'>Orden</th>
                                    <th className='text-center align-middle' width='10%'>Fecha</th>
                                    <th className='text-center align-middle' width='30%'>Descripción</th>
                                    <th className='text-center align-middle' width='15%'>Acciones</th>
                                </tr>
                                <tr className='secondary'>
                                    <th className='text-center align-middle'>
                                        <div className="input-group">
                                            <Form.Control name="codeOrganismAdministrativeDocument" className='text-center' size='sm' placeholder="Organismo"  ref={register} />
                                            <Form.Control name="numberAdministrativeDocument" className='text-center' size='sm' placeholder="N°"  ref={register}/>
                                            <Form.Control name="yearAdministrativeDocument" className='text-center' size='sm' placeholder="Año"  ref={register}/>
                                        </div>
                                    </th>
                                    <th className='text-center align-middle'>
                                    <Form.Control
										as="select"
										name="type"
                                        size='sm'
										disabled={false}
                                        ref={register}
										className={"text-black-color"}
									>
										<option value={''} selected>Seleccione una opcion...</option>
										{
											orderFormsReadyToDisplay(payOrderTypeList)?.map(item => (
                                                <option className='text-black-color' value={item} key={item.id}>
													{getOrderPayTypeValueToShow(item)}
												</option>
											))
										}
									</Form.Control>
                                    </th>
                                    <th className='text-center align-middle'>
                                        <Form.Control name="number" placeholder="N° Orden" className='text-center' size='sm' type='number' ref={register}/>
                                    </th>
                                    <th className='text-center align-middle'>
                                        <Form.Control name="datePaymentOrder" className='text-center' size='sm' type='date' ref={register}/>
                                    </th>
                                    <th className='text-center align-middle'>
                                        <Form.Control name="description" placeholder="Descripción" className='text-center' size='sm' type='text' ref={register}/>
                                    </th>
                                    <th className='text-center align-middle'>
                                        <div className='d-flex justify-content-around'>
                                             <ActionIcon
                                                 size="lg"
                                                 id="search-button"
                                                 className="btn-primary search-button text-white-color"
                                                 toolTipText="Filtrar"
                                                 icon={faFilter}
                                                 type='submit'
                                                 onSubmit={onSubmitForm}
                                                />
                                             <ActionIcon
                                                 size="lg"
                                                 id="clean-filter"
                                                 className="btn-primary clean-filter text-white-color"
                                                 toolTipText="Limpiar filtros"
                                                 icon={faBroom}
                                                 type='reset'
                                                 onClick={()=> clearFilters()}
                                                />
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='text-black-color'>
                                {
                                    hasorderPayList && !userAdmServIsFetching && listIdsServiceAdministrative
                                        ?
                                        <>
                                            {orderPayList?.map((item) => (
                                                <tr key={item?.id} className={item?.state === "ANULADO" && 'text-danger'}>
                                                    <td className='text-center align-middle'>
                                                        {item?.administrativeDocument?.codeOrganism}-{item?.administrativeDocument?.number}/{item?.administrativeDocument?.year} 
                                                    </td>
                                                    <td className='text-center align-middle truncate maxTruncate' title={item?.type}>
                                                        {getOrderPayTypeValueToShow(item?.type)}
                                                    </td>
                                                    <td className='text-center align-middle'>
                                                        {item?.number}
                                                    </td>
                                                    <td className='text-center align-middle'>
                                                        {dateNeutralFormatedToShowARG(item?.datePaymentOrder)}
                                                    </td>
                                                    <td className='text-center align-middle maxTruncate'>
                                                        {item?.description}
                                                    </td>
                                                    <td className='text-center align-middle' >
                                                        {
                                                            organismsPermissions?.canView &&
                                                                <ActionIcon
                                                                    size='lg'
                                                                    id='credit-query'
                                                                    className='mx-2'
                                                                    toolTipText={`Detalle de la Orden de Pago ${item?.state === "ANULADO" ? 'anulada' : ''}`}
                                                                    icon={faSearch} onClick={() => onClickDetail(item)}
                                                                />
                                                        }
                                                        <ActionIcon 
                                                            size='lg' 
                                                            className='mx-2'
                                                            hidden={item.type != 'ORDEN_DE_PAGO_DE_PROVEEDORES' || item?.state === "ANULADO"} 
                                                            toolTipText='Descargar Orden de Pago' 
                                                            icon={faDownload} 
                                                            onClick={() => handleDownload(item?.id)} 
                                                        />
                                                        <ActionIcon 
                                                            size='lg' 
                                                            className='mx-2'
                                                            hidden={item.type != 'ORDEN_DE_PAGO_DE_HABERES'} 
                                                            toolTipText='Descargar Orden de Pago' 
                                                            icon={faDownload} 
                                                            onClick={() => handleDownload(item?.id)} 
                                                        />
                                        				<ActionIcon 
                                                            size='lg' 
                                                            className='mx-2'
                                                            hidden={item.type != 'ORDEN_DE_PAGO_DE_HABERES'} 
                                                            toolTipText={'Descargar Comprobante del Expediente'} 
                                                            icon={faFilePdf} 
                                                            onClick={() => clickDownloadTransactionReportPDF(item)} 
                                                        />
                                                        {
                                                            organismsPermissions?.canUpdate &&
                                                                <ActionIcon
                                                                    size='lg'
                                                                    hidden={item?.state === "ANULADO"}
                                                                    className={((item?.slimFundRequest || administrativeServiceClose(servicesData, item?.administrativeServiceId)) && 'not-allowed') + ' mx-2 '}
                                                                    toolTipText={validateActionIcon(item, VALIDATE_ACTION_TYPE.UPDATE).text}                                                                
                                                                    icon={faEdit}
                                                                    onClick={() => validateActionIcon(item, VALIDATE_ACTION_TYPE.UPDATE).validation && onClickEdit(item)}
                                                                />
                                                        }
                                                        {
                                                            organismsPermissions?.canDelete &&
                                                                <ActionIcon
                                                                    size='lg'
                                                                    hidden={item?.state === "ANULADO"}
                                                                    id='delete'
                                                                    className={((item?.slimFundRequest || administrativeServiceClose(servicesData, item?.administrativeServiceId)) && 'not-allowed') + ' mx-2 '}
                                                                    toolTipText={validateActionIcon(item, VALIDATE_ACTION_TYPE.DELETE).text}
                                                                    icon={faTrash}
                                                                    onClick={() => validateActionIcon(item, VALIDATE_ACTION_TYPE.DELETE).validation && onClickDelete(item)}
                                                                />
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                        :
                                        <tr>
                                            <td colSpan={colSpanCellsNumber} className='text-center'>
                                                {
                                                    !isFetching && listIdsServiceAdministrative &&
                                                        <Alert variant='info' className='mb-0'>
                                                            <FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
                                                            No hay registros
                                                        </Alert>
                                                }
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        </Table>
                        { 
                            hasorderPayList &&
                                <RCPagination
                                    activePage={pageNumber + 1}
                                    itemsCountPerPage={defaultParams.size}
                                    totalItemsCount={totalCount}
                                    totalItems={totalCount}
                                    thePage={pageNumber + 1}
                                    onChange={onPageChange}
                                    innerClass="justify-content-center"
                                />  
                        }
                    </Form>
                    </Card.Body>
                </Card>
            </Container>
        </>
        <AppLoading isLoading={isFetching || reportIsFetching || userAdmServIsFetching || servicesDataIsFetching || reportAffectationsByAdministrativeDocumentDownloadIsFetching || orderPayByIdIsFetching || servicesByUserAndExerciseIsFetching} />
    </>;
}
export default OrderPayListPage; 